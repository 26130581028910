import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";

export default function LexiconPage({ data, pageContext }) {
  return (
    <>
      <SEO
        title={
          pageContext.word
            ? `Lexicon - definition of word ${pageContext.word.name}`
            : `Lexicon - all Words`
        }
      />
    </>
  );
}

export const query = graphql`
  query {
    words: LOOWIAG {
      allEntries {
        definition
        id
        name
        origin
        period
        pubDate
        status
      }
    }
  }
`;
